<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '3503b15e-5ac2-4677-b19f-942a4a2fe23e'
    }
  }
}
</script>
